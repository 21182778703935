import "./home.css"

export default function Home() {
	return (
		<div className="container">
			<main class="grid">
				<article>
					<img src="https://cdn.discordapp.com/attachments/852444793752649789/908297533183385610/Avatar-jones.png"/>
					<div class="text">
						<h2>Jones#1913</h2>
						<p>Owner / Developer</p>
						<a href="/jones" class="btn btn-primary btn-block">Find out more</a>
					</div>
				</article>
				<article>
					<img src="https://cdn.discordapp.com/attachments/852444793752649789/908277820638248980/Avatar-hayden.png"/>
					<div class="text">
						<h2>Hayden#6789</h2>
						<p>Owner / Developer</p>
						<a href="/hayden" class="btn btn-primary btn-block">Find out more</a>
					</div>
				</article>
				<article>
					<br/>
					<br/>
					<img src="https://cdn.discordapp.com/attachments/636505908419231746/908298504961654814/Carter.PNG"/>
					<br/>
					<br/>
					<div class="text">
						<h2>Global#6355</h2>
						<p>UI Developer</p>
						<a href="/global" class="btn btn-primary btn-block">Find out more</a>
					</div>
				</article>
				<article>
					<img src="https://global-uploads.webflow.com/5e157548d6f7910beea4e2d6/60415153fea4767867265233_Microinfluencers%201140%20wide-64.png"/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					<div class="text">
						<h2>Discord</h2>
						<a href="https://discord.gg/CBTtWXQE6Q" class="btn btn-primary btn-block">Join</a>
					</div>
					</article>
					<article>
					<img src="https://www.saashub.com/images/app/service_logos/167/xdjwxuhveyfr/large.png?1611106815"/>
					<div class="text">
						<h2>Tebex</h2>
						<a href="https://store.scriptshare.net/category/1955505" class="btn btn-primary btn-block">Browse</a>
					</div>
					</article>
					<article>
					<img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"/>
					<div class="text">
						<h2>GitHub</h2>
						<a href="https://github.com/ScriptShare-net" class="btn btn-primary btn-block">Find out more</a>
					</div>
				</article>
			</main>
		</div>
	)
}
